<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items marginBottom20">
          <el-button size="medium" type="primary" icon="el-icon-plus" @click="handleCreate">添加角色</el-button>
        </div>
      </template>

      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="small" icon="el-icon-edit" @click="handleEdit(row)">编辑</el-button>
        <el-button type="text" size="small" icon="el-icon-connection" @click="handleAuthorize(row)">授权</el-button>
        <el-button type="text" size="small" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
      </template>
    </avue-crud>

    <el-dialog
        :title="form.id ? '编辑' : '新增'"
        v-if="show"
        :visible.sync="show"
        width="30%"
        :before-close="handleClose">
      <avue-form ref="form"
                 v-model="form"
                 :option="formOption"
                 @submit="handleSubmit">
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>

    <el-dialog
        class="dialogPower"
        title="角色权限配置"
        v-if="showPower"
        :visible.sync="showPower"
        width="30%"
        top="10vh"
        :before-close="handleClosePower">
      <el-tree
          ref="tree"
          :props="props"
          :data="menuRole"
          show-checkbox
          node-key="id"
          :default-expanded-keys="defaultCheckedKeys"
          :default-checked-keys="defaultCheckedKeys"
          @check-change="handleCheckChange"></el-tree>
      <span slot="footer" class="dialog-footer">
            <el-button
                :icon=" submitType ? 'el-icon-circle-check' : 'el-icon-loading' "
                type="primary"
                size="small"
                :disabled="!submitType"
                @click="handleSubmitPower">{{ submitType ? '确 定' : '提交中' }}</el-button>
            <el-button icon="el-icon-circle-close" size="small" @click="handleClosePower">取 消</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import {roleList, roleDelete, roleMenuTree, roleAdd, roleView, roleUpdate, roleMenuView, roleMenuUpdate} from "@/api/system/role";
export default {
  name: "index",
  data(){
    return{
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option: {
        title: '角色管理',
        titleSize: 'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: 'center',
        menuAlign: 'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        column: [
          {
            label:'识别码',
            prop:'code'
          },
          {
            label:'名称',
            prop:'name'
          },
          {
            label:'类型',
            prop:'remarks'
          },
          {
            label:'状态',
            prop:'state',
            type: 'radio',
            dicData: [{label: '启用',value: 1},{label: '禁用',value: 2}],
          },
          {
            label:'创建人',
            prop:'createBy'
          },
          {
            label:'创建时间',
            prop:'createTime'
          },
          {
            label:'最后修改时间',
            prop:'updateTime'
          }
        ]
      },

      show: false,
      form: {},
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        labelWidth: 100,
        column: [
          {
            label: '识别码',
            prop: 'code',
            maxlength: 20,
            showWordLimit: true,
            span: 24,
            rules: [{
              required: true,
              message: "请输入识别码",
              trigger: "blur"
            }]
          },
          {
            label:'名称',
            prop:'name',
            maxlength: 20,
            showWordLimit:true,
            span: 24,
            rules: [{
              required: true,
              message: "请输入角色名称",
              trigger: "blur"
            }]
          },
          {
            label:'状态',
            prop:'state',
            type: 'radio',
            value: 1,
            dicData: [{label: '启用',value: 1},{label: '禁用',value: 2}],
            span: 24,
            rules: [{
              required: true,
              message: "请选择状态",
              trigger: "blur"
            }]
          },
          {
            label:'类型',
            prop:'remarks',
            maxlength: 20,
            showWordLimit:true,
            span: 24,
            rules: [{
              message: "请输入类型",
              trigger: "blur"
            }]
          }
        ]
      },

      showPower: false,
      props: {
        label: 'name',
        value: 'id',
        isLeaf: 'leaf',
      },
      menuRole: [],
      powerId: '',
      defaultCheckedKeys: [],
      submitType: true
    }
  },
  mounted() {
    this.getRoleMenuTree();
  },
  methods: {
    onLoad(){
      this.showLoading = true;
      roleList({
        page: this.page.currentPage,
        size: this.page.pageSize
      }).then(res => {
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    // 添加角色
    handleCreate(){
      // console.log('添加角色')
      this.show = true;
    },
    // 编辑
    async handleEdit(row){
      // console.log('编辑',row)
      const { data } = await roleView(row.id)
      this.form = data
      this.show = true;
    },
    // 授权
    async handleAuthorize(row){
      // console.log('授权',row)
      await this.getRoleMenuView(row.id);
      this.showPower = true;
      this.powerId = row.id;
    },
    async getRoleMenuTree(){
      const { data } = await roleMenuTree();
      // console.log(data)
      this.menuRole = data || [];
    },
    async getRoleMenuView(id){
      const { data } = await roleMenuView(id)
      // console.log(data)
      this.defaultCheckedKeys = data
    },
    // 删除
    handleDelete(row){
      // console.log('删除',row)
      this.$confirm('是否删除此角色数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        roleDelete(row.id).then(res => {
          if(res.code === 200){
            this.$message.success('删除成功');
            this.onLoad();
          }else{
            this.$message.error(res.msg);
          }
        })
      })
    },
    // 提交
    handleSubmit(form,done){
      // console.log(form)
      if(form.id){
        roleUpdate(form).then(res=>{
          if(res.code === 200){
            this.$message.success('添加成功');
            this.onLoad();
            this.show = false;
            setTimeout(()=>{
              this.resetForm();
            },200)
          }else{
            this.$message.error(res.msg)
            done()
          }
        }).catch(()=> done())
      }else{
        roleAdd(form).then(res=>{
          if(res.code === 200){
            this.$message.success('添加成功');
            this.onLoad();
            this.show = false;
            setTimeout(()=>{
              this.resetForm();
            },200)
          }else{
            this.$message.error(res.msg)
            done()
          }
        }).catch(()=> done())
      }
    },
    // 关闭
    handleClose(){
      this.show = false;
      setTimeout(()=>{
        this.resetForm();
      },200)
    },
    // 重置form
    resetForm(){
      this.form = {
        id: '',
        code: '',
        name: '',
        state: '',
        remarks: '',
      }
    },
    handleCheckChange(){
      console.log(this.$refs.tree.getCheckedKeys())
    },
    // 提交
    handleSubmitPower(){
      console.log(this.$refs.tree.getCheckedKeys())
      roleMenuUpdate({
        id: this.powerId,
        menuIds: this.$refs.tree.getCheckedKeys()
      }).then(res=>{
        if(res.code === 200){
          this.$message.success('配置成功');
          this.showPower = false;
          this.onLoad();
          this.resetPower();
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    // 关闭
    handleClosePower(){
      this.showPower = false;
      this.resetPower();
    },
    resetPower(){
      this.powerId = '';
      this.defaultCheckedKeys = [];
    }
  }
}
</script>

<style scoped>
.dialogPower /deep/.el-dialog .el-dialog__body{
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
}
</style>